import axios from 'axios';
import { useEffect, useState } from 'react';
import { show_alerta } from '../../funciones.js';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarRoles.php`;

function Roles({idUsuario,setUsuarios}) {
    const [roles, setRoles] = useState([]);
    

   const loggeduserRolId =localStorage.getItem('loggeduserRolId');


   
    useEffect( ()=>{
        //console.log(idUsuario)
        axios.post(URL,{'id_usuario':idUsuario, 'modo': 'buscarRoles', 'rol': loggeduserRolId})
        .then(res =>{
            if(!res.data.error){ 
                setRoles(res.data);
            }else{
                setRoles([]);
            }
        })
        .catch(err=>{
            console.log(err);
        })
    },[idUsuario])

    function handleSubmint(e) {
        e.preventDefault();
        console.log(e.target.flexRadioDefault.value)
        axios.post(URL,{'id_usuario':idUsuario, 'modo': 'nuevoRol', 'idRol': e.target.flexRadioDefault.value})
        .then(res =>{
            var tipo = res.data[0];
            var msj = res.data[1];
            show_alerta(msj,tipo);
            if(tipo==='success'){
                    document.getElementById('btnCerrar2').click();
                    setUsuarios([]);
                }
            })
            .catch(err=>{
                show_alerta('Error en la accion ','error');
                console.log(err);
            })
      }
    return ( 
        <div>
            <form id='form' onSubmit={handleSubmint}>
            {roles.map((r)=>(
                <div key={r.id} className="form-check ms-2 mb-2">
                    <input className="form-check-input" type="radio" value={r.id} name="flexRadioDefault" id={`chek_${r.id}`} />
                    <label className="form-check-label" htmlFor={`chek_${r.id}`}><i className={r.icono}></i> {r.nombre}</label>
                </div>
            ))}
                <div className='row pe-3 pt-2'>
                    <button type='submit' className='btn btn-success m-2'>Agregar el rol seleccionado</button>
                </div>
                
            </form>
        </div>
     );
}

export default Roles;