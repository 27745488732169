import './css/Usuarios.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UsuariosCaja from "./UsuariosCaja";
import {Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import CONFIG from '../../config';

const URL_ADM  = `${CONFIG.API_URL}/admin.php`;

function Usuarios({acceder, rol}){
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();
   // const loggeduserROlId =localStorage.getItem('loggeduserRolId');

    useEffect(()=>{
        if(acceder){
            //console.log(rolSelect);
            if(rol===null){
                navigate("/");
            }else{
               // setRol(loggeduserROlId);
        
                axios.post(URL_ADM)
                .then(res =>{
                    if(!res.data.error){ 
                        //console.log('datos2: '+ JSON.stringify(res.data));  
                        setRoles(res.data);
                    }else{
                        setRoles([]);
                }
                })
                .catch(err=>{
                    console.log(err);
                })
            }
        }else{
            localStorage.clear();
            navigate('/');
        }
    },[])

    return(
            <div className='container-principal'>
                <h4 className='titulo-area'>Usuarios</h4>
                <Link className='btn btn-outline-secondary m-2' to={'/usuarios/0'}><i className="fa-solid fa-users m-1"></i> Administrar Usuarios</Link>
                <Link className='btn btn-outline-secondary m-2' to={'/usuarios/-1'}><i className="fa-solid fa-users-slash m-1"></i> sin rol</Link>
                <div className="container-usuarios">    
                    {roles.map((c) => (
                        rol<=c.id &&
                        <UsuariosCaja key={c.id} rol={c.rol} cantidad={c.cant} icon={c.icon} info={c.info} id={c.id} />
                    ))}
                </div>


            </div>
    );
}

export default Usuarios;