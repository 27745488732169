import './css/Aulas.css';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect , useState} from "react";
import { show_alerta } from '../../funciones.js';
import PlanificacionFormIA from './PlanificacionFormIA';
import PlanificacionForm from './PlanificacionForm';
import PLanificacionVista from './PlanificacionVista.js';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarCursos.php`;

function Planificacion({acceder, rol}) {
    const { idMC, idCG } = useParams();
    const navigate = useNavigate();
    const [verAgregarPlanificacion, setVerAgregarPlanificacion] = useState(false);
    const [planificacion, setPlanificacion] = useState({
      id:null,
      id_curso_grupo: idCG,
      id_curso_equipo_docente: '',
      fecha: '',
      tipo: '',
      introduccion: '',
      id_curso: '',
      propositos: '',
      estrategia_metodologica: '',
      evaluacion: '',
      entorno: '',
      recursos: '',
      bibliografia: '',
  });

    const data= {
        'id_curso_grupo' : idCG,
        'modo': 'buscarCursoPlanificacion'
    }

    useEffect(() => {
        if(acceder){
            if((rol===null)){
                navigate("/");
            }else{
                console.log("data:"+ JSON.stringify(data));
                buscaPlanificacion(data); 
            }
        }else{
            localStorage.clear();
            navigate('/');
        }           
    }, [acceder, rol]);
    
    const buscaPlanificacion =  (d) =>{
        axios.post(URL, d)
        .then(res =>{
            if(!res.data.error){   
                setPlanificacion(res.data.planificacion);
                //console.log("data:"+ JSON.stringify(res.data.planificacion));
                //console.log("cursos:"+planificacion);
            }else{
                setPlanificacion({
                  id:null,
                  id_curso_grupo: idCG,
                  id_curso_equipo_docente: '',
                  fecha: '',
                  tipo: '',
                  introduccion: '',
                  id_curso: '',
                  propositos: '',
                  estrategia_metodologica: '',
                  evaluacion: '',
                  entorno: '',
                  recursos: '',
                  bibliografia: '',
              });
            }
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const agregarPlanificacion = async (metodo,parametros) =>{
        await axios({method:metodo, url:URL, data:parametros})
        .then(res =>{
            console.log(res.data);
            var tipo = res.data[0];
            var msj = res.data[1];
            //console.log(msj+'-'+tipo);
            show_alerta(msj,tipo);
            setVerAgregarPlanificacion(false);
            buscaPlanificacion(data);
        })
        .catch(err=>{
            show_alerta('Error en la solicitud ','error');
            console.log(err);
        })
    }

    return (    
        <div className="container-principal">
          <div className='row'>
            <div className="col-12 mb-3">
              <div className='card'>
                <div className='card-body'>
                  {rol=== 2 &&
                  <div className='d.flex justify-content-star mb-2'>
                    <botton type='button' className='btn btn-outline-secondary btn-sm' onClick={()=>navigate('/Cursos')}>volver</botton>
                  </div>
                  }
                  <div className='row'>
                    <div className='col-7'>
                      <h4>{!verAgregarPlanificacion ? 'Planificación anual del curso' : (planificacion.id === null ? 'Nueva planificación curricular' : 'Editar la planificación')} </h4>
                    </div>
                    {(rol=== 6 || rol=== 2 ) &&
                    <>
                      {planificacion.id==null ?
                      <div className='col-5 d-flex justify-content-end'>
                          <button type='button' className={`mb-2 btn ${verAgregarPlanificacion ? 'btn-secondary' : 'btn-primary '}`} onClick={() => setVerAgregarPlanificacion(!verAgregarPlanificacion)}>
                            {verAgregarPlanificacion ? 'X' : <><i className="fa-regular fa-calendar-plus"></i> Nueva PLanificación</>}
                          </button>
                      </div>
                      :
                      <div className='col-5 d-flex justify-content-end'>
                        <button type='button' className={`mb-2 btn ${verAgregarPlanificacion ? 'btn-secondary' : 'btn-primary '}`} onClick={() => setVerAgregarPlanificacion(!verAgregarPlanificacion)}>
                        {verAgregarPlanificacion ? 'X' : <><i className="fa-regular fa-calendar-plus"></i> Editar PLanificación</>}
                          </button>
                      </div>
                      }
                    </>
                    }
                  </div>
                  <div className='row'>
                    <div className='d-none d-xl-block col-12 col-xl-2 col-xxl-3'></div>
                    <div className='col-12 col-xl-8 col-xxl-6'>
                      <div className='card-body'>
                        <div className='card-title'>
                          {verAgregarPlanificacion ? 
                            <PlanificacionForm 
                              plan={planificacion}
                              setVerAgregarPlanificacion={setVerAgregarPlanificacion} 
                              agregarPlanificacion={agregarPlanificacion} /> 
                            : (planificacion.id==null ?''
                              :<PLanificacionVista plan={planificacion}/>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className='d-none d-xl-block col-12 col-xl-2 col-xxl-3'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     );
}

export default Planificacion;