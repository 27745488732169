import './css/Aulas.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { show_alerta } from '../../funciones.js';
import PerfilLogo from '../usuarios/PerfilLogo.js';
import { asignarEstudianteAlCurso } from '../../servicios/cursoServicios';
import CONFIG from '../../config';

const URL_LISTAR = `${CONFIG.API_URL}/listarUsuarios.php`;

function CursoGrupoEstudiantes({ id_curso_grupo, estudiantesCurso, inscripciones }) {
    const [estudiantes, setEstudiantes] = useState([]);
    const [buscar, setBuscar] = useState('');
    const [buscar2, setBuscar2] = useState('');
    const [estudianteSeleccionado, setEstudianteSeleccionado] = useState('');
    let filtroGrupoEstudiantes = [];
    let filtroEstudiantes = [];

    useEffect(() => {
        axios.post(URL_LISTAR, { 'id_rol': 7, 'modo': 'buscaUsuariosPorRol' })
            .then(res => {
                if (!res.data.error) {
                    setEstudiantes(res.data);
                } else {
                    setEstudiantes([]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    function handleSubmint1(e) {
        e.preventDefault();
        // Verificación si el estudiante ya está asignado al curso
        const estudianteYaAsignado = estudiantesCurso.some(estudiante => estudiante.id === estudianteSeleccionado);

        if (estudianteYaAsignado) {
            show_alerta('Estudiante ya está inscripto a este curso', 'error');
            return;
        }else{
            asignarEstudiante(id_curso_grupo,estudianteSeleccionado)
        }
    }
    const asignarEstudiante = async (curso,estudiante) => {
        const res = await asignarEstudianteAlCurso(curso,estudiante)
        console.log("respuesta: "+JSON.stringify(res));
        var tipo = res.data[0];
        var msj = res.data[1];
        show_alerta(msj, tipo);
       
    }
    // filtro1
    filtroGrupoEstudiantes = !buscar
        ? estudiantesCurso
        : estudiantesCurso.filter((dato) =>
            dato.id_curso_grupo.toLowerCase().includes(buscar.toLowerCase())
        );

    // filtro2
    filtroEstudiantes = !buscar2
        ? estudiantes
        : estudiantes.filter((dato) =>
            dato.nombre.toLowerCase().includes(buscar2.toLowerCase()) ||
            dato.apellido.toLowerCase().includes(buscar2.toLowerCase()) ||
            dato.documento.toString().toLowerCase().includes(buscar2.toLowerCase())
        );

    return (
        <>
            <div>
                <div className='row border-bottom mx-2 mb-2'>
                    <div className='col-8'>
                        <h5><i className="fa-solid fa-graduation-cap"></i> Total de estudiantes en el curso {filtroGrupoEstudiantes.length}</h5>
                    </div>
                    <div className='col-4 text-end'>
                        <button type='button' className='btn btn-light btn-sm' data-bs-toggle="modal" data-bs-target="#modalAgregarEstudiantes">+ estudiante</button>
                    </div>
                </div>
                <div className='mx-2'>
                {Array.isArray(filtroGrupoEstudiantes) && filtroGrupoEstudiantes.length > 0 
                && filtroGrupoEstudiantes.map((ec) => (
                        <div key={ec.id} className='my-1'><PerfilLogo usuario={ec} version="extendida" /></div>
                    ))}
                </div>
            </div>

            <div className="modal fade" id="modalAgregarEstudiantes" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                Estudiantes
                                <div className="input-group ">
                                    <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-magnifying-glass"></i></span>
                                    <input type="text" className="form-control" id='buscar' name='buscar' defaultValue={buscar2} onChange={(e) => setBuscar2(e.target.value)} placeholder='buscar...' aria-label='buscar' aria-describedby='basic-addon1' />
                                </div>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="form1" onSubmit={handleSubmint1}>
                                {filtroEstudiantes.map((d) => (
                                    <div key={d.id}>
                                        <input className="form-check-input" type="radio" name='docente' onClick={() => setEstudianteSeleccionado(d.id)} id={`chek_${d.id}`} value={d.id} />
                                        <label className="form-check-label mx-1 small" htmlFor={`chek_${d.id}`}> {d.apellido}, {d.nombre} ({d.documento})</label>
                                    </div>
                                ))}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" name='botonCerrar' id="botonCerrar" data-bs-dismiss="modal">Close</button>
                            <button type="submit" form='form1' className='btn btn-success' data-bs-dismiss="modal">Aceptar seleccionado</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CursoGrupoEstudiantes;
