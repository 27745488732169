import './css/Mensajes.css';
import axios from 'axios';
import { useState , useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { show_alerta } from '../../funciones.js';

import MensajeAdjuntos from './MensajeAdjuntos';
import CONFIG from '../../config';
import MensajesCrudForm from './MensajesCrudForm';
import PerfilLogo from '../usuarios/PerfilLogo.js';

const URL_LISTAR_MENSAJES = `${CONFIG.API_URL}/listarMensajes.php`;
const URL  = `${CONFIG.API_URL}/operarMensajes.php`;

function Mensaje({acceder, rol}){
    const navigate = useNavigate();
    const [responder, setResponder] = useState(false);
    const [respuestas, setRespuestas] = useState([]);
    const [mensaje, setMensaje] = useState({});
    const { mensajeId, origen } = useParams();
    const userId =localStorage.getItem('loggeduserId');

    const data= {
        'id' :  mensajeId,
        'tipo' : origen
    }

    useEffect( ()=>{
        if(!acceder){
            localStorage.clear();
            navigate('/');
        }else{
            axios.post(URL_LISTAR_MENSAJES, data)
            .then(res =>{
                console.log('dd: '+JSON.stringify(res.data));
                if(!res.data.error){ 
                    setMensaje(res.data.dato);
                    buscaRespuestas()
                }else{
                    setMensaje({});                
                }
            })
            .catch(err=>{
                console.log(err);
            })

        }
    },[])

    // Scroll al formulario cuando 'responder' cambia a true se mueve hasta .respuestaForm
    useEffect(() => {
        if (responder) {
            const formulario = document.querySelector('.respuestaForm');
            formulario.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [responder]);


    const buscaRespuestas = async()=>{
        const dato= {
            'id' :  userId,
            'tipo' : 'RESPUESTAS',
            'id_mensaje':mensajeId
        }
        try {
            const res = await axios.post( URL_LISTAR_MENSAJES, dato);
             //console.log('dd: '+JSON.stringify(res.data));
             if(!res.data.error){
                setRespuestas(res.data)
             }else{
                setRespuestas([]);
             }
        } catch (err) {
           // show_alerta('Error en la solicitud', 'error');
            console.log(err);
        }
    }

    const enviarFormData = (data) =>{
		enviarSolicitud("POST", data);
    };

    const enviarSolicitud = async (metodo, parametros) => {
        try {
            const res = await axios({ method: metodo, url: URL, data: parametros });
            //console.log(res.data)
            const [tipo, msj] = res.data;
            console.log(msj + '-' + tipo);
            show_alerta(msj, tipo);
            buscaRespuestas()
            setResponder(false);
        } catch (err) {
           // show_alerta('Error en la solicitud', 'error');
            console.log(err);
        }
    };

    return(
        <div className='container-principal'>
          {mensaje && 
            <div className="m-3">
                <h4><i className="fa-solid fa-envelope-open"></i> {mensaje.asunto}</h4>
                <br />
                <div className='row'>
                    <div className='col-sm-8 mensaje-de'>
                        <div className='row'>
                            <div className='col-1' ><PerfilLogo id={mensaje.id_usuario} version="logo_solo" /></div>
                            <div className='col' >
                                {mensaje.nombre} {mensaje.apellido} <span className='mensaje-de-usuario'>({mensaje.de})</span>
                                <div className='mensaje-para'>para:{mensaje.para}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4 mensaje-fecha'>
                        {mensaje.fecha}
                    </div>
                </div>
                
                <p className='pt-2 mensaje-original'>{mensaje.mensaje}</p>
                <hr />
                <div className='text-center'>
                { mensaje.adjunto =='Si' ? <><i className="fa-solid fa-paperclip"></i> <MensajeAdjuntos mensaje_id={mensaje.id_mensaje} /> </>: '' }
                </div>
            </div>
            }
            <div>
                {respuestas.length > 0 && 
                <div className='ms-4'>
                    <h5>Respuestas:</h5>
                    {respuestas.map((mr, index)=>
                        <div key={index}>
                            <div  className='row'>
                                <div className='col-1' ><PerfilLogo id={mr.id_u} version="logo_solo" /></div>
                                <div className='col' >
                                    {`${mr.de} <<${mr.nombre}, ${mr.apellido}>>`}
                                    <div className='mensaje-para'><strong>Para:</strong>{mr.para}</div>
                                </div>
                            </div>
                            <p className='mensaje-respuesta'>{mr.mensaje}</p>
                        </div>
                    )}
                </div>
                }
            </div>
            <div>
                <div className='Pie-mensaje'>
                    <a href="#" className='btn btn-outline-secondary m-1' onClick={()=>setResponder(true)}><i className="fa-solid fa-reply"></i> responder</a>
                    <a href="#" className='btn btn-outline-secondary'><i className="fa-regular fa-trash-can"></i></a>
                </div>
            </div>
            {responder && 
                <div className='respuestaForm p-4'>
                    <MensajesCrudForm 
                    enviarFormData={enviarFormData} 
                    respuesta_a={mensajeId} 
                    para_r={`${mensaje.de} <<${mensaje.nombre} ${mensaje.apellido}>>,`}
                    asunto_r={`Re: ${mensaje.asunto}`} />
                </div>
            }
            

        </div>
    )
}

export default Mensaje;