import './css/Aulas.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Espera from '../Espera';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarCursos.php`;

function MisCursos({acceder, rolSelect, rol}) {
    const [espera, setEspera] = useState(false);
    const [cursos, setCursos] = useState([]);
    const loggeduserId =localStorage.getItem('loggeduserId');
    const loggeduserNombre =localStorage.getItem('loggeduserNombre');

    const navigate = useNavigate();
    

    useEffect(() => {
        if(acceder){
            if((rol===null) || (rol<6)  || (rol===8)){
                navigate("/");
                //console.log(rolSelect);
            }else{
                const data= {
                    'id_usuario' : loggeduserId,
                    'modo': 'buscarCursosUsuario',
                    'llama':rol
                }
                // console.log(data);
                setEspera(true);
                axios.post(URL, data)
                .then(res =>{
                    //console.log(JSON.stringify(res.data));
                    (!res.data.error)?setCursos(res.data):setCursos([]);
                    setEspera(false);
                })
                .catch(err=>{
                    console.log(err);
                })
            }
        }else{
            localStorage.clear();
            navigate('/');
        }        
    }, [acceder, rol, navigate, loggeduserId]);

    const entrarEnCurso =(cur)=>{
        localStorage.setItem('loggeduserCurso', cur.id );
        localStorage.setItem('loggeduserCursoGrupo',  cur.id_curso_grupo);
        localStorage.setItem('loggeduserCursoGrupoO',  JSON.stringify(cur));
        navigate(`/MC/${cur.id}`);
    }
    const verPlanificacion =()=>{
        //falta desarrollar
    }
    return ( 
            <div className='container-principal'>
                <h4>Hola, {loggeduserNombre} bienvenido.</h4>
                {!espera ? 
                <div className='row row-cols-1 row-cols-md-3 row-cols-xl-4'>  
                {cursos.length==0?"No Eres docente en ningun curso":<>          
                    {cursos.map((c, index)=>(
                    <div key={index}>
                        <div className="card-estructura" onClick={() => entrarEnCurso(c)} >
                            <img src={c.imagen === '' ? `${CONFIG.API_URL}/${c.caratula_formacion}` : c.imagen} className="card-img-top" alt='imagen-decorativa' />
                            <p className="card-text">Ciclo lectivo: {c.cohorte}</p>
                            <div className="caja-cuerpo2">
                                <h6 >{c.orden}° {c.nombre_espacio}</h6>
                                <p>Sala/grupo: {c.denominacion}</p> 
                                <p><i className="fa-solid fa-list-check"></i> Planificacion: {(c.id_planificacion > 0) ? <button type='button' className='btn btn-outline-primary btn-sm' onClick={()=>verPlanificacion(c.id_planificacion)}>SI</button>:'NO'}</p>
                                <p><i className="fa-solid fa-people-group"></i> Estudiantes: {c.cant_estudiantes}</p>
                            </div>
                        </div>
                    </div>
                    ))}
                </>    
                }
                </div>:<div className='container m-3'><Espera visible={espera} /></div> } 
            </div>
     );
}

export default MisCursos;