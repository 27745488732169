import './css/Aulas.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


import Espacios from './Espacios';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarFormacion.php`;

function Formacion({acceder, rol}) {
    const [datosFormacion, setDatosFormacion] = useState('');
    const { formacionId } = useParams();
		
    const navigate = useNavigate();

    const data= {
        'id' :  formacionId,
        'modo': 'buscarFormacionID'
    }

    useEffect( ()=>{
        if(acceder){
            if((rol===null)||(rol>3)){
                navigate("/");
            }else{
                axios.post(URL, data)
                .then(res =>{
                    //console.log(res.data)
                    if(!res.data.error){ 
											setDatosFormacion(res.data);
                    }else{
											setDatosFormacion('');
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            }
        }else{
            localStorage.clear();
            navigate('/');
        }            
    },[acceder,rol])

	
			
    return ( 
        <div className='container-principal fuente-formacion'>
        	{datosFormacion ?
            <div  key={datosFormacion.id}>
                <div className="row">
              	    <div className='col-12'>
                        <div className='card p-2'>
                            <img className='imagen-caratula'  src={`${CONFIG.API_URL}/${datosFormacion.caratula}`} width="160px" />
                            <h3>{datosFormacion.nombre_formacion} </h3>
                        </div>
                    </div>
                	
				</div>
				<div className=''>
                    <h5 className='text-secondary '>{!datosFormacion.resolucion_p=='' ? `resolucion: ${datosFormacion.resolucion_p}` : ''}</h5>
                </div>             
                <Espacios id_formacion={datosFormacion.id} />
            </div>
			:''}
        </div>
     );
}

export default Formacion;