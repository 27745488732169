import './css/Mensajes.css';
import axios from 'axios';
import { useState , useEffect} from 'react';
import CONFIG from '../../config';

const URL_LISTAR_MENSAJES = `${CONFIG.API_URL}/listarMensajes.php`;

function MensajeAdjuntos(props){
    const [adjuntos, setAdjuntos] = useState([]);
    
    const data= {
        'id' :  props.mensaje_id,
        'tipo'  :'ADJUNTOS'
    }

    useEffect( ()=>{
        axios.post(URL_LISTAR_MENSAJES, data)
        .then(res =>{
            //console.log(res.data);
            if(!res.data.error){ 
                setAdjuntos(res.data);
            }else{
                setAdjuntos([]);                
            }
        })
        .catch(err=>{
            console.log(err);
        })
 
    },[])

    const handleEventContainerClick = (e) => e.stopPropagation();

    return(
        <div className="contenedor-adjuntos" onClick={handleEventContainerClick}>
             { adjuntos.length===0 ? '' :
                    adjuntos.map(ad => (
                    <a key={ad.id} href={`${CONFIG.API_URL}/${ad.path_archivo}`} className="adjunto small"><i className="fa-solid fa-paperclip"></i> {ad.nombre_archivo}</a>
            ) )
            }  
        </div>
    )
};

export default MensajeAdjuntos;