import "./css/RolesCaja.css";
import { useNavigate } from "react-router-dom";

function RolesCaja({rol, rolSelect}){
    const navigate = useNavigate();
    const ingresar=(id)=>{
       // console.log(id);
        localStorage.removeItem('loggeduserCurso');
        localStorage.removeItem('');
        localStorage.removeItem('loggeduserCursoGrupoO');
        //localStorage.setItem('loggeduserRolId', id );
        rolSelect(id);
        navigate("/Principal");
    }

return(
    <div id="hero" className="m-3 ">
            <div  className="icon-box" onClick={()=>ingresar(rol.id)}>
                <div className="icon d-flex justify-content-center"><i className={rol.icono}></i></div>
                <h4 className="title d-flex justify-content-center">{rol.nombre}</h4>
                <p className="description2"></p>
            </div>
    </div>
    );
}

export default RolesCaja;