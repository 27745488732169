import './css/Aulas.css';
import Espera from '../Espera';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClaseActividadesForm from './ClaseActividadesForm';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarClases.php`;

function ClaseActividades({setCantActividades, enviarSolicitud, id_curso, id_clase, setEditaActividad, editaActividad}) {
    const [actividadesCurso, setActividadesCurso] = useState([]);
    const [actividadesClase, setActividadesClase] = useState([]);
    const [visible, setVisible] = useState(false);    
    
    const data= {
        'id_curso' : id_curso,
        'modo': 'buscarActividadesCurso'
    }
    
    useEffect(() => {
        buscaActividadesCurso(data);
    }, [id_clase, editaActividad]);

    const buscaActividadesCurso = (d) =>{
        setVisible(true);
        axios.post(URL, d)
        .then(res =>{
            if(!res.data.error){   
                setActividadesCurso(res.data)
                //filtrar las actividades de la clase actual
                setActividadesClase(res.data.filter((actividad)=>{
                    if( actividad.id_clase==id_clase ){
                        return true;
                    }
                    return false;
                }))
                setCantActividades(actividadesClase.length);
            }else{
                setActividadesCurso([]);
            }
            setVisible(false);           
        })
        .catch(err=>{
            console.log(err);
        })   
    }

    const quitarActividad=(id_trabajo_clase)=>{
        enviarSolicitud('DELETE',{'id':id_trabajo_clase,'tabla':'trabajo_clase'})
        setEditaActividad(false);
    }

    return (    
        <div>
        {editaActividad && <ClaseActividadesForm setEditaActividad={setEditaActividad} id_clase={id_clase} actividadesCurso={actividadesCurso} enviarSolicitud={enviarSolicitud} />}
        {!visible ? 
            actividadesClase.length!=0 && actividadesClase.map((acl)=>(
                <div key={acl.id} className="actividad-clase-link row">
                    <div className='col-11'>
                        <a href={acl.id} className='texto-item'>{acl.titulo.substr(0, 35)}- {acl.id_trabajo_clase}</a>
                        <p>{acl.desarrollo}</p>
                    </div>    
                    {editaActividad && <div className='col-1'><a onClick={()=>quitarActividad(acl.id_trabajo_clase)}><i className="fa-regular fa-trash-can"></i></a></div>}
                </div>
            ))
        : <div className='container m-3'><Espera visible={visible} /></div>
        }                
    </div>
     );
}

export default ClaseActividades;