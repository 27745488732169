import './css/UsuariosCaja.css';
import {Link} from 'react-router-dom';


function UsuariosCaja(props){
    

    return(
            <div className='caja'>
                    <div className='caja-circulo-logo'><i className={props.icon}></i></div>
                    <h5 className='caja-titulo'>Rol {props.rol}</h5>
                    <hr></hr>
                    <div className='caja-cantidades'><span>{props.cantidad}</span> usuario/s </div>
                    <div className='text-center caja-botonera'><Link className='btn btn-sm btn-outline-secondary m-2' to={`/Usuarios/${props.id}`}><i className="fa-solid fa-magnifying-glass"></i> Ver todos</Link></div>
                    <div className='caja-info'><span><b>info reciente:</b><br/> {props.info}</span></div>
            </div>
    );
}

export default UsuariosCaja;