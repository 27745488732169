import './css/Mensajes.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import MensajeAdjuntos from './MensajeAdjuntos.js';

function MensajesRecibidos({ userId, eliminarMensaje, buscaMensajes, mensajesRecibidos }){
    const navigate = useNavigate();
    
    
    useEffect(() => {
        if (userId) {
            buscaMensajes(userId, 'RECIBIDOS');
        }
       
    }, [userId]);
    
    return (
        <div className='container'>
            <table className="table table-sm table-fixed table-hover" width="100%">
                <thead><tr><td colSpan="5"></td></tr></thead>
                <tbody>
                { mensajesRecibidos.length === 0 ? ( 
                    <tr><td colSpan="4" className='text-center'>Sin mensajes</td></tr> 
                ) : (
                    mensajesRecibidos.map(m => (
                        <tr key={m.id_mensajeR} className='mensaje-row' onClick={() => navigate(`/mensajes/enRecibidos/${m.id_mensajeR}`)}>
                            <td className="align-top" valign="top">
                                <span><i className={`fa-regular ${m.estado == 1 ? "fa-envelope-open" : "fa-envelope"}`}></i></span>
                            </td>
                            <td width="15%">
                                <span className="small"><b>{m.apellido}, {m.nombre && m.nombre.length > (23 - m.apellido.length) ? m.nombre.substring(0, (23 - m.apellido.length)) : m.nombre}</b></span>
                            </td>
                            <td width="70%">
                                <span className="align-top small"><b>{m.asunto}</b> - {m.mensaje && m.mensaje.length > (80 - m.asunto.length) ? m.mensaje.substring(0, (80 - m.asunto.length)) : m.mensaje}...</span>
                                <MensajeAdjuntos mensaje_id={m.id_mensaje} />
                            </td>
                            <td width="10%">
                                <span className="small" align="right">{m.fecha ? m.fecha.substring(0, 10) : ''}</span>
                              
                            </td>
                            <td width="5%">
                               
                                <button type='button' 
                                className='btn btn-sm btn-ligth'  
                                onClick={(e) => {
                                        e.stopPropagation(); // Detener la propagación del evento
                                        eliminarMensaje(m.id_mensajeR,'mensajes_recibidos');
                                }}
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>					  
            </table>
        </div>
    );
}


export default MensajesRecibidos;