import './css/estudiantes.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Espera from '../Espera';
import PerfilLogo from '../usuarios/PerfilLogo.js';
import { useNavigate } from "react-router-dom";
import CONFIG from '../../config';

const URL = `${CONFIG.API_URL}/operarEstudiantes.php`;

function Estudiantes({acceder, rol}) {
    const [estudiantes, setEstudiantes] = useState([]);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
   
    const loggeduserCurso =localStorage.getItem('loggeduserCurso');
    const loggeduserCursoGrupo =localStorage.getItem('loggeduserCursoGrupo');


    useEffect(() => {
        if(acceder){
            if((rol===null)){
                navigate("/");
            }else{  
                const data= {
                    'id_curso' : loggeduserCurso,
                    'id_grupo' : loggeduserCursoGrupo,
                    'modo': 'buscarEstudiantesCurso'
                }      
                obtenerEstudiantes(data);
            }
        }else{
            localStorage.clear();
            navigate('/');
        }                   
    }, [acceder, rol, navigate, loggeduserCurso, loggeduserCursoGrupo]);

    const obtenerEstudiantes=(dato)=>{
        setVisible(true);
        axios.post(URL, dato)
        .then(res =>{
            if(!res.data.error){ 
                setEstudiantes(res.data);
            }else{
                setEstudiantes([]);
            }
            setVisible(false);
        })
        .catch(err=>{
            console.log(err);
        })
    }
    
    return (
        <div className="container-principal">
            <small>curso_grupo: #{loggeduserCursoGrupo}</small>
            <h3 className='estudiantes-titulo'>Estudiantes en el curso {rol==6 && <button type='button' className='btn btn-outline-info btn-sm'>+ <i className="fa-solid fa-graduation-cap"></i></button>}</h3>
            {rol==6 &&
            <div className='row '>
                <div className="col-12">
                    <ul className="nav nav-tabs justify-content-end">
                        <li className="nav-item">
                            <button className="nav-link active" aria-current="page" >calificaciones</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link active" >Asistencia</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link active" >actividades</button>
                        </li>
                    </ul>
                </div>
            </div>
            }
            {!visible ? 
                <>{estudiantes.length>0 ?
                  <table className='table'>
										
										<tbody>
                    {estudiantes.map((e)=>(
                    <tr key={e.id} >
                        <td className='col-sm-7 col-12'>
                            <PerfilLogo usuario={e} version="extendida" />
                        </td>
                        <td>

												</td>
                        <td className='col-sm-5 col-12 estudiante-botonera'>
													{rol ==6 &&
													<>
                            <button type='button' className='btn btn-info btn-sm m-1'> Calificar</button>
                            <button type='button' className='btn btn-warning btn-sm'>- <i className="fa-solid fa-graduation-cap"></i></button>
													</>
													}
												</td>
                    </tr>  
                ))}
										</tbody>
									</table> :<div>Sin estudiantes</div>
                }</> 
                : <Espera />
            }
        </div>
      );
}

export default Estudiantes;