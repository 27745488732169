import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cuestionarios from "../actividades/Cuestionarios";
import Actividades from "../actividades/Actividades";

function Laboratorio({acceder, rol}) {
   const [ver, setVer] = useState('');
   const { idMC } = useParams();
   const navigate = useNavigate();
   const idCG =localStorage.getItem('loggeduserCursoGrupo');

    useEffect(() => {
        if(acceder){
            if((rol===null)){
                navigate("/");
            }else{        

            }
        }
    }, []);
    return (    
        <div className="container-principal">
            <h3>Laboratorio</h3>
            <div>
                <button type="button" className="btn btn-sm btn-primary me-1" onClick={()=>setVer('actividades')}>actividades</button>
                <button type="button" className="btn btn-sm btn-primary me-1" onClick={()=>setVer('cuestionario')}>Cuestionarios</button>
            </div>
            {ver==='actividades' && <Actividades idCG={idCG} rol={rol} />}
            {ver==='cuestionario' && <Cuestionarios idCG={idCG} rol={rol}  />}
            
            
        </div>
     );
}

export default Laboratorio;