import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import CONFIG from '../../config';

function ResponderCuestionario(){
    const { formId } = useParams();
    const [formulario, setFormulario] = useState(null);
    const [respuestas, setRespuestas] = useState([]);
		const [mostrarResultados, setMostrarResultados] = useState(false);
		const [resultados, setResultados] = useState([]);
    
		useEffect(() => {
        const fetchForm = async () => {
            const response = await axios.get(`${CONFIG.API_URL}/operarCuestionario.php?form_id=${formId}`);
            console.log("dd: "+JSON.stringify(response.data.data))
            setFormulario(response.data.data);
            setRespuestas(response.data.data.preguntas.map(() => ''));
        };
        fetchForm();
    }, [formId]);

    const handleRespuestaChange = (index, value) => {
        const nuevasRespuestas = [...respuestas];
        nuevasRespuestas[index] = value;
		//console.log(value);
        setRespuestas(nuevasRespuestas);
    };

		const handleSubmit = async () => {
			try {
				const datos_respuesta={
					formulario_id: formId,
					respuestas: formulario.preguntas.map((p, index) => ({
							pregunta_id: p.id,
							respuesta_texto: respuestas[index]
					}))
				}
				console.log(datos_respuesta)
					const response = await axios.post(`${CONFIG.API_URL}/operarCuestionario.php`,datos_respuesta);
					// Aquí puedes manejar la lógica para mostrar los resultados al usuario
					//console.log(response.data);
					setMostrarResultados(true);
					setResultados(response.data.resultado)
			} catch (error) {
					console.error("Error enviando las respuestas: ", error);
			}
		};

    return (
        formulario ? (
            <div className='container-principal'>
							{!mostrarResultados ?	
								<div className='card p-4'>
									<h2>{formulario.titulo}</h2>
									<p>{formulario.descripcion}</p>
									{formulario.preguntas.map((p, index) => (
											<div key={index} className='card p-2 my-2'>
												<p>{index+1}. <strong>{p.pregunta_texto}-{p.pregunta_tipo}</strong></p>
													{p.pregunta_tipo === 'text' ? (
															<input
																	type="text"
																	value={respuestas[index]}
																	onChange={(e) => handleRespuestaChange(index, e.target.value)}
															/>
													) : (
															p.opciones.map((opc, oIndex) => (
																<div key={oIndex} className="form-check">
																	<input 
																		className="form-check-input" 
																		type={p.pregunta_tipo} 
																		name={`pregunta_${index}`}  
																		value={opc}  
																		onChange={(e) => handleRespuestaChange(index, e.target.value)} 
																		id={`pregunta_${oIndex}_${index}`} 
																	/>
																	<label className="form-check-label" htmlFor={`pregunta_${oIndex}_${index}`}>
																		{opc}
																	</label>
																</div>
															))
													)}
											</div>
									))}
									<button className='btn btn-primary my-3' onClick={handleSubmit}>Enviar Respuesta</button>
								</div>
							:
							<>
								<div>correctas: {resultados.correctas}</div>
								<button type='button' className='btn btn-info' onClick={()=>setMostrarResultados(false)}>Volver a repetir</button>
							</>
							}
							</div>
        ) : (
            <p>Cargando formulario...</p>
        )
    );
};

export default ResponderCuestionario;
