
import './css/Usuarios.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarTablaUsuario.php`;

function PerfilLogo({id, usuario, version, fecha}) {
    const [datosUsuario, setDatosUsuario] = useState('');
    const [iniciales, setIniciales] = useState('-');

    useEffect(() => {
        
        if(typeof id !== "undefined"){
            buscarDatosUsaurio(id); 
        }
        if(typeof usuario !== "undefined"){
            setDatosUsuario(usuario)
            const inic = usuario.nombre.toUpperCase().substr(0,1)+usuario.apellido.toUpperCase().substr(0,1)
            setIniciales(inic)
        }
    }, [id, usuario, version, fecha]);

    const obtenerEdad=(dateString)=> {
        let hoy = new Date()
        let fechaNacimiento = new Date(dateString)
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
        
        if (dateString==null){
            edad=null;
        }else{
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
              ) {
                edad--
              }      
        }
        return edad
    }

    const buscarDatosUsaurio=(id_usaurio)=>{
        axios.get(`${URL}?id_usuario=${id_usaurio}`)
        .then(res =>{
            if(!res.data.error){ 
                setDatosUsuario(res.data)
                setIniciales(res.data.nombre.toUpperCase().substr(0,1)+res.data.apellido.toUpperCase().substr(0,1))
                //console.log(res.data)
            }else{
                setDatosUsuario('')
            }
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const cambiaFormatoFecha =(dateString) => {
        const date = new Date(dateString);
        const daysOfWeek = [
            'domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'
        ];
        const months = [
            'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
        ];
    
        const dayOfWeek = daysOfWeek[date.getUTCDay()];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    
        return `${dayOfWeek} ${day} de ${month} de ${year} ${hours}:${minutes}:${seconds}`;
    }

    return (    
        <div className='contenedor-logoPerfil'>
            {datosUsuario.imagen_perfil === null ? (
                <div className="text-white iniciales">{iniciales}</div>
            ) : (
                <img className='imgCh' src={`${CONFIG.API_URL}/${datosUsuario.imagen_perfil}`} alt="Perfil" />
            )}
            {(version=="logo_solo" || version=="foro" )?'':
            <div className={(version=="extendida")?'nombreApellidox14':'nombreApellido'}>
                <span>{datosUsuario.nombre} </span>
                <span>{datosUsuario.apellido}</span>
                {version=="extendida" &&
                <div className='apodoEdad'>{datosUsuario.apodo} {obtenerEdad(datosUsuario.fecnac)===null?'':<small>({obtenerEdad(datosUsuario.fecnac)} años)</small>}</div>
                }
            </div>
            }
            {version=="foro"&&  
            <div className='nombreApellidox14'>
                <span>{datosUsuario.nombre} </span>
                <span>{datosUsuario.apellido}</span>
                <div className='apodoEdad'>{cambiaFormatoFecha(fecha)} </div>
            </div>
            }
        </div>
     );
}

export default PerfilLogo;