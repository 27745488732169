import './css/RolesSelector.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import RolesCaja from './RolesCaja';
import CONFIG from '../config';

const URL = `${CONFIG.API_URL}/buscaRolesUsuario.php`;

function RolesSelector({acceder,rolSelect,rol}){
    const navigate = useNavigate();

    const [rolesUsuario, setRolesUsuario] = useState([]);
    const loggeduser =localStorage.getItem('loggeduser');
   
    const data= {
        'usuario' :  loggeduser,
        'modo'  :'buscarRolesUsuario'
    }
    
    useEffect( ()=>{
         if(acceder){
            //console.log(rol);
            //console.log(data);
             axios.post(URL, data)
             .then(res =>{
                 //console.log(res.data);
                 if(!res.data.error){ 
                     setRolesUsuario(res.data);
                 }else{
                     setRolesUsuario([]);
                 }
             })
             .catch(err=>{
                 console.log(err);
             })
     
        }else{
            localStorage.clear();
            rolSelect(null);
            navigate('/');
        }
    },[])

    return(
        <>
            <div className='container-principal'>
                <h3 className='d-flex justify-content-center'>Seleccionar rol</h3>
                <div className='contenedor-roles'>
                    
                    { !!rolesUsuario && rolesUsuario.map(r =>(
                        <div key={r.id}>
                            <RolesCaja rol={r} rolSelect={rolSelect}/>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RolesSelector;