import React, { useState, useEffect } from 'react';
import './css/Mensajes.css';

function Calendario({ eventos, fechaMostrar }) {
  const [diasEnCalendario, setDiasEnCalendario] = useState([]);
  

  useEffect(() => {
    generarDiasCalendario();
  }, [eventos, fechaMostrar]);

  const eventosDelDia = (fecha) => {
    return eventos.filter((evento) => new Date(evento.fecha).toDateString() === fecha.toDateString());
  };

  const generarDiasCalendario = () => {
    const fechaInicial = new Date(fechaMostrar);
    const primerDia = new Date(fechaInicial);
    primerDia.setDate(primerDia.getDate() - primerDia.getDay()-7); // Empezar desde el domingo 
    const dias = [];

    for (let i = 0; i < 42; i++) { // 6 semanas (42 días)
      const diaActual = new Date(primerDia);
      diaActual.setDate(primerDia.getDate() + i);
      dias.push(diaActual);
    }
    setDiasEnCalendario(dias)
  };

  const esHoy = (fecha) => {
    const hoy = new Date();
    return (
      fecha.getFullYear() === hoy.getFullYear() &&
      fecha.getMonth() === hoy.getMonth() &&
      fecha.getDate() === hoy.getDate()
    );
  };

  const esDiaAnterior = (fecha) => {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0); // Eliminar la hora para solo comparar la fecha
    return fecha < hoy;
  };

  const esMismoMes = (fecha) => {
    //const hoy = new Date();
    return fecha.getMonth() === fechaMostrar.getMonth();
  };

  const mostrarEvento=(evt)=>{
    alert(evt.evento)
  }
  return (
    <div className='mb-2'>
      <div className="calendar-grid">
        <div className='calendario-titulo'>Dom</div><div className='calendario-titulo'>Lun</div><div className='calendario-titulo'>Mar</div>
        <div className='calendario-titulo'>Mie</div><div className='calendario-titulo'>Jue</div><div className='calendario-titulo'>Vie</div>
        <div className='calendario-titulo'>Sab</div>
        { diasEnCalendario.map((dia, index) => (
          <div key={index}
            className={`calendar-day ${esDiaAnterior(dia) ? 'dia-anterior' : ''} ${!esMismoMes(dia) ? 'mes-diferente' : ''} ${esHoy(dia) ? 'hoy' : ''}`}
            
          >
            <span>{dia.getDate()}{esHoy(dia) && ' (hoy)'}</span>
            {eventosDelDia(dia).map((evento, idx) => (
              <div key={idx} 
              className={`${evento.tipo_recordatorio} ${esDiaAnterior(dia) ? 'evento-pasado' : 'evento'}`}
              onClick={()=>mostrarEvento(evento)} onContextMenuCapture={()=>alert('ddd')}>
                {evento.creada_por===1 &&
                <div className='d-flex justify-content-end'>
                  <button type='button' 
                  className='btn-agenda'
                  onClick={(e)=>{
                    e.stopPropagation(); // Detener la propagación del eventoe.preventDefault()
                  }}>
                  X
                  </button>
                </div>
                }
                 {evento.evento}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Calendario;
