import  './css/UsuariosLista.css';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { show_alerta } from '../../funciones.js';
import { useNavigate } from "react-router-dom";

import UsuarioRoles  from "./UsuarioRoles";
import UsuarioCrudForm from './UsuarioCrudForm.js';
import Roles from './Roles.js';
import CONFIG from '../../config.js';

const URL_LISTAR = `${CONFIG.API_URL}/listarUsuarios.php`;
const URL  = `${CONFIG.API_URL}/operarTablaUsuario.php`;

function UsuariosLista({acceder, rol, rolSelect}){
    const navigate = useNavigate();

    const [buscar, setBuscar] = useState("");
    const [usuarios, setUsuarios] = useState([]);
    const { userId } = useParams();
    
    const [datoAEditar, setDatoAEditar] = useState(null);
    const [idUsuario, setIdUsuario] = useState(0);

    useEffect(()=>{
        if(acceder){
            if((rol===null)||(rol>3)){
                navigate("/");
            }
        }else{
            localStorage.clear();
            rolSelect(null)
            navigate('/');
        }
    },[])


    const crearData = (data) =>{
        enviarSolicitud("POST", data);
    };

    const actualizarData = (data) =>{
        console.log(data);
        enviarSolicitud("PUT", data);
    };

    const eliminarData = (id) =>{
        const MySwal= withReactContent(Swal); 
        MySwal.fire({
            title: '¿Seguro de eliminar el usuario?',
            icon: 'question', 
            text: 'No se podrá dar marcha atrás',
            showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
        })
        .then(res=>{
            if(res.isConfirmed){
                enviarSolicitud('DELETE',{'id':id})
            }
            else{
                show_alerta('El usuario NO fue eliminado');
            }
        });
    };

    const data= {
        'id_rol' :  userId,
        'modo'  :'buscaUsuariosPorRol'
    }

    useEffect( ()=>{
        axios.post(URL_LISTAR, data)
        .then(res =>{
            if(!res.data.error){ 
                setUsuarios(res.data);
            }else{
                setUsuarios([]);
            }
        })
        .catch(err=>{
            console.log(err);
        })
        resultado= usuarios;
    },[usuarios])

    const handleBuscar = (e) => {
        setBuscar(e.target.value)
       // console.log(e.target.value)
    }

    //metodo de filtrado
    let resultado=[];
    if(!buscar){
        resultado= usuarios;
    }else{
        resultado= usuarios.filter((dato)=>{
            if( (dato.nombre.toLowerCase().includes(buscar.toLocaleLowerCase())) || 
                (dato.apellido.toLowerCase().includes(buscar.toLocaleLowerCase())) ||
                (dato.usuario.toLowerCase().includes(buscar.toLocaleLowerCase())) ||
                (dato.documento.toString().toLowerCase().includes(buscar.toLocaleLowerCase())) ){
                return true;
            }
            return false;
        });
    }

    //constantes para paginacion
    const [paginaActual, setPaginaActual]=useState(1);
    const registrosPorPagina=10;
    const ultimoIndice = paginaActual * registrosPorPagina;
    const primerIndice = ultimoIndice - registrosPorPagina;
    const registros = resultado.slice(primerIndice, ultimoIndice);
    const npaginas =Math.ceil(resultado.length / registrosPorPagina);
   
    const enviarSolicitud = async (metodo, parametros) =>{
        await axios({method:metodo, url:URL, data:parametros})
            .then(res =>{
                var tipo = res.data[0];
                var msj = res.data[1];
              //  console.log(msj+'-'+tipo);
                show_alerta(msj,tipo);
                if(tipo==='success'){
                    document.getElementById('btnCerrar').click();
                }
            })
            .catch(err=>{
                show_alerta('Error en la solicitud ','error');
                console.log(err);
            })
    }

    return(
        <div className='container-principal'>    
            <h4>Usuarios con rol { userId }</h4>
            { userId == 0 ? (<button type="button" className='btn btn-outline-success m-2' onClick={()=>setDatoAEditar(null)}  data-bs-toggle="modal" data-bs-target="#modalEditar" ><i className="fa-solid fa-circle-plus"></i> nuevo</button>) : ''}
            <div className="input-group mb-3 me-2">
                    <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-magnifying-glass"></i></span>
                    <input type="text" className="form-control" id='buscar' name='buscar' defaultValue={buscar} onChange={handleBuscar} placeholder='buscar...' aria-label='buscar' aria-describedby='basic-addon1' />
            </div>
            <small>filtro <b>{resultado.length} / {usuarios.length}</b> </small>  
            <table className='table table-sm table-striped table-hover mt-2 shadow-lg'>
            <thead className='bg-cabecera-tabla small'>
                    <tr>
                        <th>Apellido</th>
                        <th>Nombre</th>
                        <th>Nom. usuario</th>
                        <th>documento</th>
                        { userId == 0 ? (<th>rol/es</th>) : ''}
                        { userId == 0 ? (<th>acc.</th>) : ''}
                        { userId == 7 ? (<th>acc.</th>) : ''}
                    </tr>
            </thead>
            <tbody className='small'>
            { !!registros && 
                    registros.map(u => (
                        <tr key={u.id}>
                            <td>{u.apellido}</td>
                            <td>{u.nombre}</td>
                            <td>{u.usuario}</td>
                            <td>{u.documento}</td>
                            { userId == 0 ? (<td><UsuarioRoles id_usuario={u.id} /></td>) : ''} 
                            { userId == 0 ? (
                            <td>
                                <button type="button" className='btn btn-sm btn-primary me-1' onClick={()=>setIdUsuario(u.id)} data-bs-toggle="modal" data-bs-target="#modalRoles"><i className='fa-solid fa-circle-plus'></i> rol</button>
                                <button type="button" className='btn btn-sm btn-warning me-1' onClick={()=>setDatoAEditar(u)} data-bs-toggle="modal" data-bs-target="#modalEditar" ><i className='fa fa-solid fa-pencil'></i></button>
                                <button type="button" className='btn btn-sm btn-danger me-1' onClick={()=>eliminarData(u.id)}><i className='fa fa-solid fa-trash'></i></button>
                            </td>
                            ) : ''}
                            
                        </tr>
                    ))}
            </tbody>
            </table>
            <nav>
                <ul className='pagination'>
                    <li className='page-item'>
                        <a href='#' className='page-link' onClick={prePage}>Prev</a>
                    </li>
                    
                    <li className='page-item'>
                        <a href='#' className='page-link' onClick={nexPage}>Prox </a>
                    </li>
                </ul>
            </nav>


            <div id="modalEditar" className="modal fade" aria-labelledby="modalUsuarios" aria-hidden="true">
                <div className='modal-dialog'>
                    <div className="modal-content">
                        <div className='modal-header'>
                            <h1 className="modal-title fs-5" id="ModalLabel"></h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='modal-body'>
                           
                            <UsuarioCrudForm crearData={crearData} actualizarData={actualizarData} datoAEditar={datoAEditar} setDatoAEditar={setDatoAEditar} />
                           
                        </div>
                        <div className="modal-footer">
                            <button type="button" id='btnCerrar' className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalRoles" className="modal fade" aria-labelledby="modalUsuarios" aria-hidden="true">
                <div className='modal-dialog'>
                    <div className="modal-content">
                        <div className='modal-header'>
                            <h1 className="modal-title fs-5" id="ModalLabel">Roles Disponibles</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='modal-body'>
                            <Roles idUsuario={idUsuario} setUsuarios={setUsuarios} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" id='btnCerrar2' className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
    function prePage(){
        if(paginaActual !== 1){
            setPaginaActual(paginaActual - 1);
        }
    }
    function nexPage(){
        if(paginaActual !== npaginas){
            setPaginaActual(paginaActual + 1);
        }    
    }
    
}


export default UsuariosLista;