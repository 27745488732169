import { Link } from 'react-router-dom';
import './css/Aulas.css';
import CONFIG from '../../config';

function FormacionCaja({formacion, setVerForm,setDatosFormacion,eliminarFormacion}) {

    const editar=(f)=>{
        //console.log(f.nombre_formacion);
        setDatosFormacion(f);
        setVerForm(true);
    }

    return (
            <div className='card-estructura'>
                <div className='caja-imagen-top'>
                    {formacion.caratula!="" ?
                        <img src={`${CONFIG.API_URL}/${formacion.caratula}`} className='card-img-top'/> 
                    :<div className='caja-deco-top'></div> 
                    } 
                </div>
                <div className='caja-cuerpo'>
                    <h5 className='caja-titulo mt-2'>{formacion.nombre_formacion}</h5>
                    <hr/>
                    <div className='caja-medio text-center'><span className='formacion-texto-nivel'>Nivel {formacion.tipo_formacion}</span> | <span>creado {formacion.año}</span></div>
                    <hr/>
                    <div className='text-center caja-botonera'>
                        <Link className='btn btn-sm btn-outline-secondary btn-block me-3' to={`/Formaciones/${formacion.id}`}> Espacios curriculares</Link>
                        <button type='button' className='btn btn-sm btn-outline-secondary btn-block me-1' onClick={()=>editar(formacion)}><i className="fa-solid fa-pencil"></i> </button>
                        <button type='button' className='btn btn-sm btn-outline-secondary btn-block' onClick={()=>eliminarFormacion(formacion.id)} ><i className="fa-solid fa-trash-can"></i> </button>
                    </div>
                    <div className='caja-info'></div>
                </div>
            </div>
      );
}

export default FormacionCaja;