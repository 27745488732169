function verActividad({actividad, setMostrarActividad}) {
    return (    
        <div className="card p-4 my-2">
            <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-sm btn-secondary" onClick={()=>setMostrarActividad('')}> X </button>
            </div>
            <h3>{actividad.titulo}</h3>
            <p>{actividad.desarrollo}</p>
            <hr/>
            <p><i className="fa-regular fa-clock"></i><b> fecha de entrega:</b> {actividad.fecha_entrega}</p>
            <hr/>
            <div className="alert alert-warning" role="alert">
                <b>Formato de presentacion</b>: <br>
                </br>{actividad.forma_presentacion ? actividad.forma_presentacion: 'no especificado'}
            </div>
            <div>
                <b>Material de apoyo</b>
                {actividad.material ? actividad.material:''}
            </div>

            <div>El trabajo es de entrega <b>{actividad.tipo_trabajo}</b></div>
            <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-sm btn-secondary" onClick={()=>setMostrarActividad('')}>Cerrar</button>
            </div>
        </div>
     );
}

export default verActividad;