import './css/Aulas.css';
import Espera from '../Espera';

function ListaClases({ clases, clase, setClase, visible, editarClase, nuevaClase, editaMaterial, editaActividad }) {
    
    const seleccionarClase = (id) => {
        setClase(clases.find((c) => c.id === id));
    }

    return (    
        <>
            {!visible ?
            <>
            <select className='form-select mt-2 mb-4' 
            onChange={(e) => seleccionarClase(Number(e.target.value))} 
            value={clase?.id || ''}>
                {clases.length === 0 ? (
                    <option value={0}>No hay clases disponibles</option>
                ) : (
                    clases.map((cl) => (
                        <option key={cl.id} value={cl.id} disabled={nuevaClase || editarClase || editaMaterial || editaActividad}>
                            {cl.titulo_corto}
                        </option>
                    ))
                )}
            </select>
            </>
            : <div className='container m-3'><Espera visible={visible} /></div>
            }
        </>
    );
}

export default ListaClases;
