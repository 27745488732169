import './css/Mensajes.css';
import { useEffect, useState } from "react";
import axios from 'axios';
import { show_alerta } from '../../funciones.js';
import CONFIG from '../../config';

const URL_USUARIOS = `${CONFIG.API_URL}/operarTablaUsuario.php`;

function MensajesCrudForm({ enviarFormData, respuesta_a, para_r, asunto_r }) {
    const [de, setDe] = useState("");
    const [para, setPara] = useState(para_r);
    const [asunto, setAsunto] = useState(asunto_r);
    const [mensaje, setMensaje] = useState("");
    const [files, setFiles] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [sugerencias, setSugerencias] = useState([]);  // Estado para las sugerencias de usuarios

    useEffect(() => {
        setDe(JSON.parse(localStorage.getItem('loggeddatosuser')).usuario);
        // Fetch users once
        axios.get(URL_USUARIOS)
            .then(res => {
                setUsuarios(res.data);
            })
            .catch(err => {
                console.error("Error al obtener usuarios:", err);
            });
    }, []);

    // Filter users based on input
    useEffect(() => {
        if (para.split(',').slice(-1)[0].trim().length >= 2) {
            const filteredUsers = usuarios.filter(usuario =>
                (`${usuario.nombre} ${usuario.apellido}`).toLowerCase().includes(para.split(',').slice(-1)[0].trim().toLowerCase())
            );
            setSugerencias(filteredUsers);
        } else {
            setSugerencias([]);
        }
    }, [para, usuarios]);

    const seleccionarUsuario = (usuario) => {
        const nuevosPara = para.split(',').slice(0, -1).concat(`${usuario.usuario} <<${usuario.nombre} ${usuario.apellido}>>,`).join(', ');
        setPara(nuevosPara);
        setSugerencias([]);
    };

    function handleSubmint(e) {
        e.preventDefault();
        if (!para || !asunto || !mensaje) {
            show_alerta('Faltan datos', 'error');
            return;
        }
        const formData = new FormData();
        formData.append('respuesta_a', respuesta_a);
        formData.append('de', de);
        formData.append('para', para);
        formData.append('asunto', asunto);
        formData.append('mensaje', mensaje);

        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        console.log('Para: '+para)
        console.log('de: '+de)
        enviarFormData(formData);
        handleReset();
    }

    const handleReset = () => {
        setPara("");
        setAsunto("");
        setMensaje("");
        setFiles([]);
    }

    return (
        <div className="me-2">
            <h5>Nuevo mensaje</h5>
            <form onSubmit={handleSubmint} id='form'>
                    <input 
                        type="hidden" 
                        className="form-control"
                        name="respuesta_a" 
                        placeholder="Destinatario" 
                        value={respuesta_a} 
                    />
                <div className='input-group m-1'>
                    <span className='input-group-text'>Para</span>
                    <input 
                        type="text" 
                        className="form-control"
                        name="para" 
                        placeholder="Destinatario" 
                        value={para} 
                        onChange={(e) => setPara(e.target.value)} 
                    />
                </div>

                {sugerencias.length > 0 && (
                    <ul className="lista-sugerencias">
                        {sugerencias.map(usuario => (
                            <li
                                key={usuario.usuario} 
                                onClick={() => seleccionarUsuario(usuario)}
                            >
                                {usuario.nombre} {usuario.apellido}
                            </li>
                        ))}
                    </ul>
                )}

                <div className='input-group m-1'>
                    <span className='input-group-text'>Asunto</span>
                    <input 
                        type="text" 
                        className="form-control"
                        name="asunto" 
                        onChange={(e) => setAsunto(e.target.value)} 
                        value={asunto} 
                    />
                </div>

                <div className='form-control m-1'>
                    <textarea 
                        className="form-control mensaje-textarea" 
                        rows="20" 
                        placeholder="Mensaje aquí" 
                        id="floatingTextarea2"
                        name="mensaje" 
                        onChange={(e) => setMensaje(e.target.value)} 
                        value={mensaje} 
                    ></textarea>
                </div>                    
                
                <div className='input-group m-1 my-3'>
                    <span className='input-group-text'>
                        <i className="fa-solid fa-paperclip"></i>
                    </span>
                    <input 
                        type="file" 
                        className="form-control"
                        name="file" 
                        placeholder="Adjunto" 
                        multiple 
                        onChange={(e) => setFiles([...e.target.files])}
                    />
                </div>
                
                <input className="btn btn-success m-1" type="submit" value="Enviar" /> 
                <input className="btn btn-primary" type="reset" value="Limpiar" onClick={handleReset} />
            </form>
        </div>
    )
}

export default MensajesCrudForm;
