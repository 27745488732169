import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrincipalAdmin from "./principal/PrincipalAdmin";

function Principal({acceder,rol, mensajesSinLeer}){
    const navigate = useNavigate();
    //const loggeduserROlId =localStorage.getItem('loggeduserRolId');

    useEffect(()=>{   
        if(acceder){
            console.log(rol);
            if(rol===null){
                navigate("/");
            }
        }else{
            localStorage.clear();
            navigate("/");
        }
      },[])


    return(
        <>
            <div className='container-principal'>
                
                {rol==1 && <h5>Area Super admin</h5>}
                {rol==2 && <PrincipalAdmin mensajesSinLeer={mensajesSinLeer} rol={rol} />}
                {rol==6 && <h5>Area docente</h5>}
                {rol==7  && 
                    <>
                    <h5>Area estudiante</h5>
                    
                    </>                
                }
                {(rol ==8) && 
                    <>
                    <h5>Area estudiante/TUTOR</h5>
                    <button type="button" className="btn btn-primary">Inscripcion</button>
                    </>                
                }
                
            </div>
        </>
    );
}

export default Principal;