import React from 'react';
import { Table } from 'react-bootstrap';

function ConfiguracionTable({ configuraciones }) {
    return (
        <>
        <div className='card my-2 p-4'>
            <h4>Titulo: {configuraciones.nombre}</h4>
            <h6 className='mb-3'>Subtitulo: {configuraciones.sub_titulo}</h6>
            <div className='card p-3 mt-2'>
                <h4>imagenes y logos</h4>
                <div className='row px-3'>
                    <div className='col-5 border'><h6 className='d-flex justify-content-center'>Logo Principal Grande</h6></div>
                    <div className='col-2 border'><h6 className='d-flex justify-content-center'>Logo  pequeño</h6></div>
                    <div className='col-5 border'><h6 className='d-flex justify-content-center'>Imagen Fondo Login</h6></div>
                </div>
                <div className='row px-3'>
                    <div className='col-5 border'><img src={`../img/${configuraciones.logo_grande}`} alt="" className="img-fluid" /></div>
                    <div className='col-2 border pt-3'><img src={`../img/${configuraciones.logo_chico}`} alt=""  className="img-thumbnail"/></div>
                    <div className='col-5 border'><img src={`../img/${configuraciones.imagen_fondo}`} alt="" className="img-fluid"  /></div>
                </div>
            </div>
            <div className='card p-3 mt-4'>
                <h4>formato de icono perfil</h4>
                <div className="form-check form-switch">
                    <label className="form-check-label" htmlFor='flexSwitchCheckChecked'>Utilizar el formato cuadrado con puntas redondeadas</label>
                    <input className="form-check-input" type="checkbox" readOnly role="switch" id="flexSwitchCheckChecked" checked={configuraciones.formato_icono_perfil==='cuadrado'? 'checked ' :''} disabled/>
                </div>
            </div>
            <div className='card p-3 mt-4'>
                <h6>Configuracion de colores</h6> 
                <Table  bordered hover className="mt-3">
                    <thead>
                        <tr>
                        
                            <th className='small text-center'>Color Principal</th>
                            <th className='small text-center'>Color Secundario</th>
                            <th className='small text-center'>Color Terciario</th>
                            <th className='small text-center'>Barra Superior: Fondo</th>
                            <th className='small text-center'>Barra Superior: texto</th>
                            <th className='small text-center'>Barra Lateral: fondo</th>
                            <th className='small text-center'>Barra Lateral: iconos y texto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-center'>{configuraciones.color_principal}</td>
                            <td className='text-center'>{configuraciones.color_secundario}</td>
                            <td className='text-center'>{configuraciones.color_terciario}</td>
                            <td className='text-center'>{configuraciones.fondo_barra_superior}</td>
                            <td className='text-center'>{configuraciones.color_texto_barra_superior}</td>
                            <td className='text-center'>{configuraciones.fondo_barra_lateral}</td>
                            <td className='text-center'>{configuraciones.color_texto_barra_lateral}</td>
                        </tr>
                        <tr>
                            <td className='text-center' style={{backgroundColor: configuraciones.color_principal}}></td>
                            <td className='text-center' style={{backgroundColor: configuraciones.color_secundario}}></td>
                            <td className='text-center' style={{backgroundColor: configuraciones.color_terciario}}></td>
                            <td className='text-center' colSpan={2} style={{backgroundColor: configuraciones.fondo_barra_superior, color: configuraciones.color_texto_barra_superior}}> Barra Superior</td>
                        
                            <td className='text-center' colSpan={2} style={{backgroundColor: configuraciones.fondo_barra_lateral, color: configuraciones.color_texto_barra_lateral}}><i className="fa-solid fa-sliders"></i> Dashboard</td>
                            
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
        
    </>
    );
}

export default ConfiguracionTable;
