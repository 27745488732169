import './css/Mensajes.css';
import {useEffect, useState } from 'react';
import axios from 'axios';

import { show_alerta } from '../../funciones.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from "react-router-dom";

import MensajesRecibidos from './MensajesRecibidos';
import MensajesEnviados from './MensajesEnviados';
import MensajesEliminados from './MensajesEliminados';
import MensajesCrudForm from './MensajesCrudForm';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarMensajes.php`;
const URL_LISTAR_MENSAJES = `${CONFIG.API_URL}/listarMensajes.php`;

function Mensajes({acceder, rol}){
    const navigate = useNavigate();
    const userId =localStorage.getItem('loggeduserId')
    const [tabActiva, setTabActiva] = useState('Recibidos'); // Recibidos, Enviados, Eliminados
    const [mensajesRecibidos, setMensajesRecibidos] = useState([]);
    const [mensajesEnviados, setMensajesEnviados] = useState([]);
    const [mensajesEliminados, setMensajesEliminados] = useState([]);

    useEffect(() => {
        if(!acceder){
            localStorage.clear();
            navigate('/');
        }
    }, [acceder]);

	const mostrarMensajes = (valor) =>{
        setTabActiva(valor);
	}

    const enviarFormData = (data) =>{
		enviarSolicitud("POST", data);
        mostrarMensajes('Recibidos');
    };

    const eliminarMensaje = (id, tabla) =>{
        let texto='Se envía a la papelera para eliminarlo definitivamente elimineló de ahí.';
        if(tabla==='enviado'|| tabla==='recibido'){
            texto = 'Se eliminará definitivamente el mensaje.'
        }
        const MySwal= withReactContent(Swal); 
        MySwal.fire({
            title: '¿Seguro de eliminar el mensaje?',
            icon: 'question', 
            text: texto,
            showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
        })
        .then(res=>{
            if(res.isConfirmed){
                console.log('id: '+id+' tabla: '+tabla)
                enviarSolicitud('DELETE',{'id':id, 'tabla':tabla})
                if(tabla=='mensajes_enviados'){
                    mostrarMensajes('Enviados');
                }else{
                    if(tabla=='mensajes_recibidos'){
                       
                        mostrarMensajes('Recibidos');
                    }else{
                        mostrarMensajes('Eliminados');
                    }
                }

            }
            else{
                show_alerta(' NO fue eliminado');
            }
        });
    };

	const enviarSolicitud = async (metodo, parametros) => {
        try {
            const res = await axios({ method: metodo, url: URL, data: parametros });
            console.log(res.data)
            const [tipo, msj] = res.data;
            console.log(msj + '-' + tipo);
            show_alerta(msj, tipo);
            if (tipo === 'success') {
                document.getElementById('btnCerrar').click();
                if(parametros.tabla=='mensajes_enviados'){
                    buscaMensajes(userId, 'ENVIADOS');
                }else{
                    if(parametros.tabla=='mensajes_recibidos'){  
                        buscaMensajes(userId, 'RECIBIDOS');
                    }else{
                        buscaMensajes(userId, 'ELIMINADOS');
                    }
                }

            }
        } catch (err) {
           // show_alerta('Error en la solicitud', 'error');
            console.log(err);
        }
    };
    
    const buscaMensajes = async (id, tipo) => {
        const data = {
            'id': id,
            'tipo': tipo
        };
        try {
            const res = await axios.post(URL_LISTAR_MENSAJES, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            //console.log('Response from API:', res.data);
            if (res.data && !res.data.error && Array.isArray(res.data)) {
                if(tipo=='RECIBIDOS'){
                    setMensajesRecibidos(res.data);
                }if(tipo=='ENVIADOS'){
                    setMensajesEnviados(res.data);
                }
                if(tipo=='ELIMINADOS'){
                    setMensajesEliminados(res.data);
                }
            } else {
                if(tipo=='RECIBIDOS'){
                    setMensajesRecibidos([]);
                }if(tipo=='ENVIADOS'){
                    setMensajesEnviados([]);
                }
                if(tipo=='ELIMINADOS'){
                    setMensajesEliminados([]);
                }
            }
        } catch (err) {
            if (err.response) {
                // Server responded with a status other than 2xx
                console.error('Server Error:', err.response.data);
            } else if (err.request) {
                // Request was made but no response received
                console.error('Network Error:', err.request);
            } else {
                // Something happened in setting up the request
                console.error('Error', err.message);
            }
            //show_alerta('Error en la solicitud', 'error');
        }
    };

    return( 
        <div className="container-principal">
            <h4 className='titulo-area'>Mis Mensajes <button type="button" className='btn btn-outline-success m-2'  data-bs-toggle="modal" data-bs-target="#modalRedactar" ><i className="fa-solid fa-pencil"></i> redactar</button></h4>
            <div className="row">
                <div className="col-12 ps-3">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <button className={`nav-link ${tabActiva === 'Recibidos' && "active"}`} aria-current="page" onClick={()=>mostrarMensajes('Recibidos')}>Recibidos</button>
                        </li>
                        <li className="nav-item">
                            <button className={`nav-link ${tabActiva === 'Enviados' && "active"}`} onClick={()=>mostrarMensajes('Enviados')}>Enviados</button>
                        </li>
                        <li className="nav-item">
                            <button className={`nav-link ${tabActiva === 'Eliminados' && "active"}`} onClick={()=>mostrarMensajes('Eliminados')}>Eliminados</button>
                        </li>
                    </ul>
                </div>
                <div className="col-12 ">
					{ tabActiva === 'Recibidos' && <MensajesRecibidos userId={userId} eliminarMensaje={eliminarMensaje} buscaMensajes={buscaMensajes} mensajesRecibidos={mensajesRecibidos} /> } 
					{ tabActiva === 'Enviados' && <MensajesEnviados userId={userId} eliminarMensaje={eliminarMensaje} buscaMensajes={buscaMensajes} mensajesEnviados={mensajesEnviados} /> } 
					{ tabActiva === 'Eliminados' && <MensajesEliminados userId={userId} eliminarMensaje={eliminarMensaje} buscaMensajes={buscaMensajes} mensajesEliminados={mensajesEliminados}/>} 
					
                </div>
            </div>

			<div id="modalRedactar" className="modal fade" aria-labelledby="modalRedactar" aria-hidden="true">
                <div className='modal-dialog'>
                    <div className="modal-content">
                        <div className='modal-header'>
                            <h1 className="modal-title fs-5" id="ModalLabel"></h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='modal-body'>
                            <MensajesCrudForm 
                            enviarFormData={enviarFormData} 
                            respuesta_a={0}
                            para_r={''}
                            asunto_r={''} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" id='btnCerrar' className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Mensajes;