
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import './css/Mensajes.css';
import Calendario from './Calendario';
import CONFIG from '../../config';

const URL_CALENDARIO = `${CONFIG.API_URL}/operarCalendario.php`;

function Agenda({ acceder, rol }) {
    const navigate = useNavigate();
    const hoy = new Date()
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const [fechaMostrar, setFechaMostrar] = useState(hoy);
    const userId =localStorage.getItem('loggeduserId');

    const [eventos, setEventos] = useState([]);
    const [verAgregarEvento, setVerAgregarEvento] = useState(false);
    const [nuevoEvento, setNuevoEvento] = useState({
        evento: '',
        fecha: '',
        hora_desde: '08:00',
        hora_hasta: '23:59',
        id_curso: '',
        tipo_recordatorio: 'yo',
        creada_por: userId,
    });

    useEffect(() => {
        if(acceder){
            obtenerEventos();        
        }else{
           // localStorage.clear();
            navigate('/');
        }
    }, []);

    const obtenerEventos = async () => {
        try {
            const res = await axios.get(URL_CALENDARIO);
            if (Array.isArray(res.data)) {
                // Aplicar filtros según el rol
                const eventosFiltrados = res.data.filter(evento => {
                    const esPropio = evento.creada_por == userId;
    
                    let filtroPorRol = false;
                    switch (rol) {
                        case 1:
                        case 2:
                            filtroPorRol = ['todos', 'todosA', 'todosD', 'todosE','todosT','todosM'].includes(evento.tipo_recordatorio);
                            break;
                        case 3:
                        case 4:
                            filtroPorRol = ['todos', 'todosA'].includes(evento.tipo_recordatorio);
                            break;
                        case 5:
                        case 6:
                        case 9:
                            filtroPorRol = ['todos', 'todosD'].includes(evento.tipo_recordatorio);
                            break;
                        case 7:
                            filtroPorRol = ['todos', 'todosE'].includes(evento.tipo_recordatorio);
                            break;
                        case 8:
                            filtroPorRol = ['todos', 'todosT'].includes(evento.tipo_recordatorio);
                            break;
                        case 12:
                            filtroPorRol = ['todos', 'todosM'].includes(evento.tipo_recordatorio);
                            break;
                        default:
                            filtroPorRol = false;
                            break;
                    }
    
                    return esPropio || filtroPorRol;
                });
    
                setEventos(eventosFiltrados);
            } else {
                setEventos([]);
                console.error('La respuesta de la API no es un array:', res.data);
            }
        } catch (err) {
            console.error('Error al obtener eventos:', err);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNuevoEvento({
            ...nuevoEvento,
            [name]: value,
        });
    };

    const agregarEvento = async () => {
        try {
            console.log(nuevoEvento);
            const res = await axios.post(URL_CALENDARIO, nuevoEvento);
            console.log(res.data);
            setVerAgregarEvento(false);
            obtenerEventos();
            setNuevoEvento({
                evento: '',
                fecha: '',
                hora_desde: '',
                hora_hasta: '',
                id_curso: '',
                tipo_recordatorio: 'yo',
                creada_por: userId
            });
        } catch (err) {
            console.error('Error al agregar evento:', err);
        }
    };

    const eventosHoy = () => {
        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0); // Asegúrate de comparar solo las fechas

        return eventos.filter((evento) => {
            const fechaEvento = new Date(evento.fecha);
            fechaEvento.setHours(0, 0, 0, 0);
            return fechaEvento.getTime() === fechaActual.getTime();
        });
    };

    const proximosEventos = (dias_proximos) => {
        const fechaActual = new Date();
        const fechaLimite = new Date();
        fechaLimite.setDate(fechaActual.getDate() + dias_proximos);

        return eventos.filter((evento) => {
            const fechaEvento = new Date(evento.fecha);
            return fechaEvento >= fechaActual && fechaEvento <= fechaLimite;
        });
    };

    const moverCalendario = (accion) => {
        // Crear una nueva fecha basada en la fecha actual
        let nuevaFecha = new Date(fechaMostrar);
        switch (accion) {
            case 'PROXIMO':
                // Incrementar el mes de la nueva fecha
                nuevaFecha.setMonth(nuevaFecha.getMonth() + 1);
                if(nuevaFecha.getFullYear() === hoy.getFullYear() &&
                nuevaFecha.getMonth() === hoy.getMonth()){
                 //   console.log('es hoy')
                    nuevaFecha = hoy;
                }else{
                   // console.log('NO es hoy')
                    nuevaFecha.setDate(1);
                }
                break;
            case 'ANTERIOR':
                // Decrementar el mes de la nueva fecha
                nuevaFecha.setMonth(nuevaFecha.getMonth() - 1);
                if(nuevaFecha.getFullYear() === hoy.getFullYear() &&
                nuevaFecha.getMonth() === hoy.getMonth()){
                    //console.log('es hoy')
                    nuevaFecha = hoy;
                }else{
                   // console.log('NO es hoy')
                    nuevaFecha.setDate(1);
                }
                break;
            case 'HOY':
                //console.log('hoy: '+hoy);
                nuevaFecha = hoy;   
                nuevaFecha.setHours(0, 0, 0, 0);             
                break;
        }
        // Actualizar el estado con la nueva fecha
        //console.log('nuevaFecha: '+nuevaFecha);
        setFechaMostrar(nuevaFecha);
        //console.log('fechaMostrar: '+fechaMostrar);
    };

    return (
        <div className="container-principal">
            <div className='row'>
                <div className="col-12 col-sm-9 mb-3">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="calendario-container">
                                {!verAgregarEvento ? (
                                    <div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-5'>
                                                <h4>
                                                    {meses[fechaMostrar.getMonth()]} {fechaMostrar.getFullYear()}
                                                </h4>
                                            </div>
                                            <div className='col-12 col-sm-4'>
                                                <button type='button' className='btn btn-outline-dark btn-sm mx-1' onClick={() => moverCalendario('ANTERIOR')}>
                                                    <i className="fa-solid fa-circle-chevron-left"></i>
                                                </button>
                                                <button type='button' className='btn btn-outline-dark btn-sm mx-1' onClick={() => moverCalendario('HOY')}>
                                                    HOY
                                                </button>
                                                <button type='button' className='btn btn-outline-dark btn-sm mx-1' onClick={() => moverCalendario('PROXIMO')}>
                                                    <i className="fa-solid fa-circle-chevron-right"></i>
                                                </button>
                                            </div>
                                            <div className='col-12 col-sm-3 d-flex justify-content-end'>
                                                <button type='button' className={`mb-2 btn ${verAgregarEvento ? 'btn-secondary' : 'btn-primary '}`} onClick={() => setVerAgregarEvento(!verAgregarEvento)}>
                                                    {verAgregarEvento ? 'Cancelar' : <><i className="fa-regular fa-calendar-plus"></i> Agregar evento</>}
                                                </button>
                                            </div>
                                        </div>
                                        <Calendario eventos={eventos} fechaMostrar={fechaMostrar} />
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='d-none d-xl-block col-12 col-xl-2 col-xxl-3'></div>
                                        <div className='col-12 col-xl-8 col-xxl-6'>
                                            <div className='card-body'>
                                                <div className='card-title agregar-evento'>
                                                    <div className='row'>
                                                        <div className='col-12 col-sm-6'><h4>Agregar nuevo evento</h4></div>
                                                        <div className='col-12 col-sm-6 d-flex justify-content-end'>
                                                            <button type='button' className='mb-2 btn btn-secondary' onClick={() => setVerAgregarEvento(!verAgregarEvento)}>
                                                                X
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form onSubmit={(e) => { e.preventDefault(); agregarEvento(); }}>
                                                    <div className='form-group mb-3'>
                                                        <label>Evento:</label>
                                                        <input className='form-control' type="text" name="evento" value={nuevoEvento.evento} onChange={handleInputChange} required />
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label>Fecha:</label>
                                                        <input className='form-control' type="date" name="fecha" value={nuevoEvento.fecha} onChange={handleInputChange} required />
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label>Hora Desde:</label>
                                                        <input className='form-control' type="time" name="hora_desde" value={nuevoEvento.hora_desde} onChange={handleInputChange} required />
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label>Hora Hasta:</label>
                                                        <input className='form-control' type="time" name="hora_hasta" value={nuevoEvento.hora_hasta} onChange={handleInputChange} required />
                                                    </div>
                                                    
                                                        <input className='form-control' type="hidden" name="id_curso" value={nuevoEvento.id_curso} onChange={handleInputChange} required />
                                                 
                                                    <div className='form-group mb-3'>
                                                        <label>¿Quién lo ve?:</label>
                                                        <select className="form-select" name="tipo_recordatorio" value={nuevoEvento.tipo_recordatorio} onChange={handleInputChange} required>
                                                            <option value="yo">Solo Yo</option>
                                                            <option value="todosU">Todos los usuarios</option>
                                                            <option value="todosA">Todos los Administradores</option>
                                                            <option value="todosD">Todos los Docentes</option>
                                                            <option value="todosE">Todos los Estudiantes</option>
                                                            <option value="todosT">Todos los Tutores</option>
                                                            <option value="todosM">Todos los Maestranzas</option>
                                                        </select>
                                                    </div>
                                                  
                                                    <input className='form-control' type="hidden" name="creada_por" value={nuevoEvento.creada_por} onChange={handleInputChange}  />
                                                   
                                                    <button className='btn btn-primary m-2' type="submit">Agregar Evento</button>
                                                    <button type='button' className={`m-2 btn ${verAgregarEvento ? 'btn-secondary' : 'btn-primary '}`} onClick={() => setVerAgregarEvento(!verAgregarEvento)}>
                                                        {verAgregarEvento ? 'Cancelar' : <><i className="fa-regular fa-calendar-plus"></i> Agregar evento</>}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className='d-none d-xl-block col-12 col-xl-2 col-xxl-3'></div>
                                    </div>
                                )}
                            </div>
                            <div className="calendario-container"></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-3'>
                    <div className='card mb-3'>
                        <div className='card-body'>
                            <div className='card-title'>
                                <h5>Eventos de Hoy:</h5>
                            </div>
                            {eventosHoy().map((evento) => (
                                <div key={evento.id_evento}>
                                    <strong>{evento.evento}</strong> - {evento.hora_desde} a {evento.hora_hasta}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='card mb-3'>
                        <div className='card-body'>
                            <div className='card-title'>
                                <h5>Próximos eventos <small>(15 días)</small>:</h5 >
                            </div>
                            {proximosEventos(15).map((evento) => (
                                <div key={evento.id_evento}>
                                    <strong>{evento.evento}</strong> - {new Date(evento.fecha).toLocaleDateString()} ({evento.hora_desde} - {evento.hora_hasta})
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Agenda;
