import './css/Aulas.css';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { show_alerta } from '../../funciones.js';

import ListaClases from './ListaClases';
import Clase from './Clase';
import CONFIG from '../../config';


const URL  = `${CONFIG.API_URL}/operarClases.php`;
const loggeduserCurso =localStorage.getItem('loggeduserCurso');
const loggeduserCursoGrupo =localStorage.getItem('loggeduserCursoGrupo');

const datoClase={
        id: null,
        id_curso : loggeduserCurso,
        id_curso_grupo : loggeduserCursoGrupo,
        titulo_corto :'',
        tema :'',
        imagen_arriba :'',
        presentacion :'',
        desarrollo :'',
        cierre :'',
        imagen_abajo :'',
        fecha :'',
        creado_por :''
    }

function Clases({acceder, rol}) {
    const [clase, setClase] = useState(datoClase);
    const [clases, setClases] = useState([]);
    const [nuevaClase, setNuevaClase] = useState(false);
    const [editarClase, setEditarClase] = useState(false);
    const [editaMaterial, setEditaMaterial] = useState(false);
    const [editaActividad, setEditaActividad] = useState(false);
    const [verAreaForos, setVerAreaForos] = useState(false);
    const [visible, setVisible] = useState(false);
    const loggeduserCursoGrupo =localStorage.getItem('loggeduserCursoGrupo');
    const loggeduserClasesCurso =localStorage.getItem('loggeduserClasesCurso');
    const navigate = useNavigate();

    const data= {
        'id_curso' : clase.id_curso,
        'id_curso_grupo' : loggeduserCursoGrupo,
        'modo': 'buscarClases'
    }

    useEffect(() => {
        if(acceder){
            if((rol===null)){
                navigate("/");
            }else{        
                //console.log('id_curso: '+clase.id_curso);
                //console.log('id_curso_grupo: '+loggeduserCursoGrupo);
                //console.log('clases '+loggeduserClasesCurso);
                if(loggeduserClasesCurso){ //vemos sis hay clases en el curso
                    const parse = JSON.parse(loggeduserClasesCurso);
                    parse.sort((a, b) => b.id - a.id);
                    localStorage.setItem('loggeduserClasesCurso',  JSON.stringify(parse));
                    setClases(parse);
                    setClase(parse[0]);
                }else{
                    setClases([]);
                    //console.log('loggeduserClasesCurso:'+loggeduserClasesCurso);
                }
                 consultarAPI(data);
            }
        }else{
            localStorage.clear();
            navigate('/');
        }                   
    }, []);

    const consultarAPI=(data)=>{
        setVisible(true);
        axios.post(URL, data)
        .then(res =>{
            if(!res.data.error){ 
                //console.log('res.data'+res.data);
                const parse = res.data.sort((a, b) => a.id - b.id)
               // console.log('ddd'+parse[0]);
                setClases(parse)
                setClase(parse[0]);
                localStorage.setItem('loggeduserClasesCurso',  JSON.stringify(parse));
                //console.log(res.data[0])
            }else{
                setClases([]);
            }
            setVisible(false);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const handleNUevo =()=>{
        if(!nuevaClase){ 
            setClase(datoClase);
            //console.log("clase: "+clase);
        }else{
            //si no hay clases
            //if(){
                //setClase(datoClase)
            //}else{
                //si hay clases tomo la primera
                //setClase(clases[0]);
            //}
        }
        setNuevaClase(!nuevaClase);
    }
    const eliminarClase = (id) =>{
        const MySwal= withReactContent(Swal); 
        MySwal.fire({
            title: '¿Seguro de eliminar la clase?',
            icon: 'question', 
            text: 'No se podrá dar marcha atrás',
            showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
        })
        .then(res=>{
            if(res.isConfirmed){
                enviarSolicitud('DELETE',{'id':id})
                setClase(datoClase);
            }
            else{
                show_alerta(' NO fue eliminado');
            }
        });
    };

    const enviarSolicitud = async (metodo, parametros) =>{
        await axios({method:metodo, url:URL, data:parametros})
        .then(res =>{
		    console.log(res.data);
            var tipo = res.data[0];
            var msj = res.data[1];
            //console.log(msj+'-'+tipo);
            show_alerta(msj,tipo);            
            setEditarClase(false);
            setNuevaClase(false);
            consultarAPI(data);
        })
        .catch(err=>{
            show_alerta('Error en la solicitud ','error');
            console.log(err);
        })
    }

    return (    
        <div className="container-principal">
                <h3>Clases</h3>
                <div className="row">
                    <div className="col-12 col-sm-3">
                        {(rol==6 || rol ==9 || rol==11) &&
                        <button type='button' className={`btn btn-sm ${!nuevaClase ? 'btn-primary':'btn-secondary'} ${( editarClase || editaMaterial || verAreaForos ) ? 'disabled':''}`} onClick={()=>handleNUevo()}>{!nuevaClase ? '+ Clase' : "Cancelar Nuevo"}</button>
                        }
                        <ListaClases
                            clases={clases}
                            clase={clase}
                            setClase={setClase} 
                            visible={visible}
                            editarClase={editarClase}
                            nuevaClase={nuevaClase}
                            editaMaterial={editaMaterial}
                            editaActividad={editaActividad} />
                    </div>
                 
                    <div className="col-12 col-sm-9">
                        {(nuevaClase || clase.id==null)?'':
                         ((rol==6 || rol ==9 || rol==11) && !editaMaterial && !editaActividad) &&
                        <div>
                            { !verAreaForos &&
                            <>
                                <button type='button' className={`btn btn-sm ${!editarClase ? 'btn-warning':'btn-secondary'} ${(verAreaForos) ? 'disabled':''} mx-1`} onClick={()=>setEditarClase(!editarClase)}><i className="fa-regular fa-pen-to-square"></i> {!editarClase ? 'Editar clase' : "Cancelar Editar"}</button>
                                <button type='button' className={`btn btn-sm btn-danger ${(editarClase || verAreaForos) ? 'disabled':''} mx-1`} onClick={()=>eliminarClase(clase.id)} ><i className="fa-regular fa-trash-can"></i> Elimina Clase</button>
                            </>
                            }
                            <button type='button' className={`btn btn-sm btn-success ${editarClase ? 'disabled':''} mx-1`} onClick={()=>setVerAreaForos(!verAreaForos)}><i className="fa-regular fa-message me-1"></i> {!verAreaForos ?'Ver área Foros' : 'Cerrar área Foros'}</button>
                        </div>
                        }
                        
                        <Clase 
                            rol={rol}
                            clases={clases}
                            clase={clase} 
                            enviarSolicitud={enviarSolicitud}
                            editarClase={editarClase}
                            nuevaClase={nuevaClase}  
                            setEditaMaterial={setEditaMaterial} 
                            editaMaterial={editaMaterial}
                            editaActividad={editaActividad}
                            setEditaActividad={setEditaActividad} 
                            verAreaForos={verAreaForos}
                            />
                    </div>
                  
                </div>
        </div>
     );
}

export default Clases;